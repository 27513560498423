import React from "react";
import { useStitchAuth } from "../StitchAuth/StitchAuth";
import { shops } from "../../Stitch";
import {
  Card,
  Button,
  TextInputField,
  Tooltip,
  InfoSignIcon,
  Position,
  TickCircleIcon,
} from "evergreen-ui";

function ReviewInfo({ setForm, formData, navigation }) {
  const { currentUser } = useStitchAuth();
  const { firstName, lastName, nickName, email, shopUrl } = formData;
  const { next } = navigation;

  async function handleClose() {
    const shopInfo = {
      _id: shopUrl,
      email: email,
      approved: true,
      owner_id: currentUser.id,
    };
    await shops.insertOne(shopInfo);
    window.close();
  }

  return (
    <div className="step-container">
      <h2>Review</h2>
      <p>You are all setup to sell on Flashdrop</p>

      <div className="step-form-input-div">
        <div className="payout-info-div">
          <div className="account-connected">
            <TickCircleIcon color="success" marginRight={16} />
            <h4>Connected to Flashdrop</h4>
          </div>
          <div className="account-review">
            <h5>{shopUrl}</h5>
          </div>

          <i className="payout-subtext">
            You will be redirected back to this page upon completing Stripe
            onboaring.
          </i>
        </div>

        <div className="step-form-action-footer">
          <Button height={36} onClick={handleClose}>
            Finish
          </Button>
        </div>
      </div>
    </div>
  );
}

export default ReviewInfo;
