import React, { useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { useForm, useStep } from "react-hooks-helper";
import qs from "qs";
import Login from "../Login/Login";
import Home from "../Home/Home";
import Navbar from "../Navbar/Navbar";
import SignupForm from "../SignupForm/SignupForm";
import { StitchAuthProvider, useStitchAuth } from "../StitchAuth/StitchAuth";
import { Button } from "evergreen-ui";
import Flashlogo from "./logo";
import "./App.css";

App.propTypes = {};
export default function App() {
  return (
    <StitchAuthProvider>
      <AppUI />
    </StitchAuthProvider>
  );
}

AppUI.propTypes = {};
function AppUI() {
  const steps = [{ id: "account" }, { id: "payment" }, { id: "review" }];
  const queryParsed = window.location.search
    ? qs.parse(window.location.search.slice(1))
    : null;
  const defaultData = {
    firstName: "Jane",
    lastName: "Doe",
    nickName: "Jan",
    address: "200 South Main St",
    city: "Anytown",
    state: "CA",
    zip: "90505",
    shopUrl: queryParsed ? atob(queryParsed.u) : "",
    email: queryParsed ? atob(queryParsed.e) : "",
    password: "",
    confirmPassword: "",
    phone: "+61 4252 454 332",
  };
  const {
    isLoggedIn,
    actions: { handleLogout },
  } = useStitchAuth();
  const [formData, setForm] = useForm(defaultData);
  const { step, navigation } = useStep({ initialStep: 0, steps });

  return (
    <>
      <div className="app-container-under-header">
        <div className="nav-header-div">
          <div className="app-header-logo-container">
            <Flashlogo />
          </div>
          <div className="multi-step-div">
            <nav className="multi-step-nav">
              <ol className="cd-multi-steps text-bottom count">
                <li className={step.id === "account" ? "current" : "visited"}>
                  <a>Account</a>
                </li>
                <li
                  className={
                    step.id === "payment"
                      ? "current"
                      : step.id !== "account"
                      ? "visited"
                      : null
                  }
                >
                  <a>Payouts</a>
                </li>
                <li className={step.id === "review" ? "current" : null}>
                  <a>Review</a>
                </li>
              </ol>
            </nav>
          </div>
        </div>

        <SignupForm
          step={step}
          navigation={navigation}
          formData={formData}
          setForm={setForm}
          isLoggedIn={isLoggedIn}
        />
      </div>
    </>
  );
}

//{isLoggedIn && <Button onClick={handleLogout}>Logout</Button>}
/*
{isLoggedIn && <Button onClick={handleLogout}>Logout</Button>}
        {isLoggedIn ? <Home /> : <Login />}
        */
