import React from "react";

import AccountInfo from "./AcccountInfo";
import PaymentInfo from "./PaymentInfo";
import ReviewInfo from "./ReviewInfo";

import "./SignupForm.css";

function SignupForm({ step, navigation, formData, setForm, isLoggedIn }) {
  const { id } = step;

  const props = { formData, setForm, navigation, isLoggedIn };

  switch (id) {
    case "account":
      return <AccountInfo {...props} />;
    case "payment":
      return <PaymentInfo {...props} />;
    case "review":
      return <ReviewInfo {...props} />;

    default:
      return null;
  }
}

export default SignupForm;
