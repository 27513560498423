import React, { useState } from "react";
import { useStitchAuth } from "../StitchAuth/StitchAuth";
import {
  Card,
  Button,
  TextInputField,
  Tooltip,
  InfoSignIcon,
  Position,
} from "evergreen-ui";

function AccountInfo({ setForm, formData, navigation, isLoggedIn }) {
  const {
    firstName,
    lastName,
    nickName,
    email,
    password,
    confirmPassword,
    shopUrl,
  } = formData;
  const [error, setError] = useState(null);
  const [passwordMatch, setPasswordMatch] = useState(true);

  const { next } = navigation;
  const { actions } = useStitchAuth();

  async function loginUser() {
    console.log("Login User");
    try {
      await actions.handleLogin("userPassLogin", email, password);
      return true;
    } catch (err) {
      console.log(err);
      return false;
    }
  }

  async function signUpUser() {
    console.log("Sign up User");
    try {
      if (password === confirmPassword) {
        await actions.handleLogin("userPassSignUp", email, password);
        let status = await loginUser(email, password);
        return status;
      } else {
        setPasswordMatch(false);
        return false;
      }
    } catch (err) {
      console.log(err);
      setError(err);
      return false;
    }

    /*
    actions.handleLogin("anonymous").catch((err) => {
      console.log(err.message);
      setError(err.message);
    });*/
  }

  async function handleNext() {
    if (isLoggedIn) {
      next();
    } else {
      let loginStatus = await signUpUser();
      if (loginStatus) {
        console.log("Going next");
        next();
      } else {
        console.log("Not going next");
      }
    }
  }

  function resetError() {
    if (!passwordMatch) {
      setPasswordMatch(true);
    }
  }

  return (
    <div className="step-container">
      <h2>Seller Information</h2>
      <p>
        Please enter the information below to create a Flashdrop seller account.
        Learn more about Flashdrop
      </p>

      <div className="step-form-input-div">
        <TextInputField
          label={
            <>
              Shopify Admin URL
              <Tooltip
                content="This URL comes directly from your Shopify Admin and cannot be edited"
                position={Position.RIGHT}
              >
                <InfoSignIcon size={13} marginLeft={5} />
              </Tooltip>
            </>
          }
          placeholder="Placeholder text"
          inputHeight={36}
          inputWidth={400}
          marginLeft={20}
          value={shopUrl}
          disabled={true}
        />
        <TextInputField
          label="Email address"
          placeholder="Placeholder text"
          name="email"
          inputHeight={36}
          inputWidth={400}
          marginLeft={20}
          value={email}
          onChange={setForm}
          isInvalid={
            error && error.message === "name already in use" ? true : false
          }
          validationMessage={
            error &&
            error.message === "name already in use" &&
            "This email is already in use"
          }
        />
        <div className="pass-confirm-pass">
          <TextInputField
            label="Password"
            placeholder="password"
            type="password"
            name="password"
            required={true}
            inputHeight={36}
            inputWidth={400}
            marginLeft={20}
            onChange={setForm}
            onClick={resetError}
            value={password}
            isInvalid={
              (error &&
                error.message ===
                  "password must be between 6 and 128 characters") ||
              !passwordMatch
                ? true
                : false
            }
            validationMessage={
              error &&
              error.message === "password must be between 6 and 128 characters"
                ? "Password must be between 6 and 128 characters"
                : !passwordMatch && "Passwords do not match"
            }
          />
          <TextInputField
            label="Confirm Password"
            placeholder="password"
            type="password"
            name="confirmPassword"
            required={true}
            inputHeight={36}
            inputWidth={400}
            marginLeft={20}
            value={confirmPassword}
            onChange={setForm}
            onClick={resetError}
            isInvalid={
              (error &&
                error.message ===
                  "password must be between 6 and 128 characters") ||
              !passwordMatch
                ? true
                : false
            }
            validationMessage={
              error &&
              error.message === "password must be between 6 and 128 characters"
                ? "Password must be between 6 and 128 characters"
                : !passwordMatch && "Passwords do not match"
            }
          />
        </div>
        <div className="step-form-action-footer">
          <Button height={36} marginRight={10} onClick={handleNext}>
            Next
          </Button>
        </div>
      </div>
    </div>
  );
}

export default AccountInfo;
