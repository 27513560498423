import React, { useState } from "react";
import { Card, Button, TextInputField, TickCircleIcon } from "evergreen-ui";

function PaymentInfo({ setForm, formData, navigation }) {
  const [stripeConnected, setStripeConnected] = useState(false);
  const { firstName, lastName, nickName } = formData;

  const { next } = navigation;

  return (
    <div className="step-container">
      <h2>Payout Information</h2>
      <p>
        Flashdrop partners with Stripe for all payments and payouts occuring on
        our Marketplace.
      </p>

      <div className="step-form-input-div">
        <div className="payout-info-div">
          <h3>
            Connect with Stripe below to get paid for purcashes made through
            Flashdrop.
          </h3>
          <p>
            Flashdrop takes a <strong>10%</strong> referral fee for each product
            sold through our marketplace.<br></br>
            Learn more about how Flashdrop works in our{" "}
            <a href="0">Seller FAQ</a>
          </p>
          {stripeConnected ? (
            <div className="stripe-connected">
              <TickCircleIcon color="success" marginRight={16} />
              Connected
            </div>
          ) : (
            <div className="stripe-button-div">
              <a
                onClick={() => setStripeConnected(true)}
                className="stripe-connect"
              >
                <span>Connect with Stripe</span>
              </a>
            </div>
          )}

          <i className="payout-subtext">
            You will be redirected back to this page upon completing Stripe
            onboaring.
          </i>
        </div>

        <div className="step-form-action-footer">
          <Button height={36} onClick={next}>
            Next
          </Button>
        </div>
      </div>
    </div>
  );
}

export default PaymentInfo;
